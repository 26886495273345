import React from 'react';
import'./header.css';
import { Link, Element } from 'react-scroll';
import Navbar from '../navbar/Navbar';

function Header(props) {
    
    var name = props.data.name;
    var occupation = props.data.occupation;
    var description = props.data.description;
    var city = props.data.address.city;
    var social = props.data.social.map(function(network){
        return (
            <div className="col-2 col-lg-1" key={network.name}>
                <li><a href={network.url} target="_blank" rel="noreferrer"><i className={network.className}></i></a></li>
            </div>
        );
    })

    return(        
        <header>
            <Element name="home" className="home">
                <Navbar />
                <div className="container banner">
                    <div className="banner-text">
                        <h1>I'm {name}.</h1>
                        <h3>I'm a {city} based <span>{occupation}</span> {description}.</h3>
                        <hr />
                        <div className="row social justify-content-center">
                            {social}
                        </div>
                    </div>
                </div>
                <div className="scrolldown">
                    <Link to="about" spy={true} smooth={true} duration={1000}><i className="fa icon-down-circle fa-3x"></i></Link>
                </div>    
            </Element>
        </header>
    );
}

export default Header;