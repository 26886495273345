import React from 'react';
import { Element } from 'react-scroll';
import './about.css';

function About(props) {

    var name = props.data.name;
    var profilepic= require("../../images/"+props.data.image).default;
    var bio = props.data.bio;
    var street = props.data.address.street;
    var city = props.data.address.city;
    var state = props.data.address.state;
    var zip = props.data.address.zip;
    var phone= props.data.phone;
    var email = props.data.email;
    var resumeDownload = props.data.resumedownload;

    return(
        <Element name="about" className="about">
            <div className="container">
                <div className="row px-5">
                    <div className="col-md-4 text-center">
                        <img className="profile-pic" src={profilepic} alt="George Profile Pic" />
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-12">
                                <h2>About Me</h2>
                                <p>{bio}</p>
                            </div>

                        </div>
                        <div className="row pt-5">
                            <div className="col-md-6">
                                <h2>Contact Details</h2>
                                <p className="address">
						            <span>{name}</span><br />
						            <span>{street}<br />
						            {city} {state}, {zip}
                                    </span><br />
						            <span>{phone}</span><br />
                                    <span>{email}</span>
                                </p>
                            </div>
                            <div className="col-md-6 download">
                                <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                            </div>
                            
                        </div>
                    </div>
                   
                </div>
            </div>
        </Element>
    );
}

export default About;