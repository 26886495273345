import React from 'react';

function Section(props) {
    return(
        <div className="row experience">
            <div className="col-md-4 text-center">
                <h1><span>{props.title}</span></h1>
            </div>
            <div className="col-md-8">
                <div className="row">
                    <div className="col-md-12">
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;