import './App.css';
import Header from './header/Header';
import Footer from './footer/Footer';
import About from './about/About';
import Resume from './resume/Resume';
import Portfolio from './portfolio/Portfolio';
import { main, resume, portfolio} from '../contexts/data';
// import Contact from './contact/Contact';
// import Testimonials from './testimonials/Testimonials';

function App() {
    return (
        <div>
            <Header data={main}/>
            <About data={main}/>
            <Resume data={resume}/>
            <Portfolio data={portfolio}/>
            {/* <Testimonials data={testimonials}/> */}
            {/* <Contact /> */}
            <Footer data={main}/>
        </div>
    );
}

export default App;
