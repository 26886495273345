import React from 'react';
import { Element } from 'react-scroll';
import Experience from './components/Section';
import './resume.css';

function Resume(props) {

    var education = props.data.education.map(function(education){
        return (
            <div key={education.school} className="education">
                <h3>{education.school}</h3>
                <p className="info">
                    {education.degree} <span>&bull;</span>
                    <em className="date">{education.graduated}</em>
                </p>
                <p>{education.description}</p>
            </div>
        );
    })

    var work = props.data.work.map(function(work){
    return (
        <div key={work.company} className="work">
            <h3>{work.company}</h3>
            <p className="info">
                {work.title}<span>&bull;</span> 
                <em className="date">{work.years}</em>
            </p>
            {work.lists.map(function(list){
                return(
                    <p key={list.list}>{list.list}</p>
            );})}
        </div>
    );
    })

    var skills = props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return (
            <li key={skills.name}>
                <span style={{width:skills.level}}className={className}></span>
                <em>{skills.name}</em>
            </li>
        );
    })

    return(
        <Element name="resume" className="resume">
            <div className="container px-5">
                {/* Education */}
                <Experience title="Education" content={education} />
                {/* Work */}
                <Experience title="Work" content={work} />
                {/* Skill */}
                <div className="row skill">
                    <div className="col-md-4 text-center">
                        <h1><span>Skills</span></h1>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="bars">
                                <ul className="skills">
                                    {skills}
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </Element>
    );
}

export default Resume;