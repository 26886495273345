export const main = {
    name: "George Li",
    occupation: "Software Engineer",
    description: "with more than 4 years of experiences in software development within front-end and back-end skills as well as testing and software design",
    image: "profilepic.jpeg",
    bio: "Hello! I'm Jiahao Li, but you can call me George. Originally from China, I've made Melbourne my home for over 10 years, embracing its vibrant culture and pursuing my passion for technology. I graduated from Deakin University with a Bachelor's in Computer Science, specializing in Robotics and Cyber-Physical Computing. Beyond academics, I gained invaluable experience in the IT industry, transitioning from hardware support to software development. With over 4 years dedicated to software engineering, I specialize in .NET technologies, Blazor applications, and innovative solutions that drive digital transformation. Join me on my journey as I continue to explore the intersection of technology and creativity, developing solutions that make a difference.",
    contactmessage: "Here is where you should write your message to readers to have them get in contact with you.",
    email: "lijiahao7788@gmail.com",
    phone: "0432841621",
    address: {
        street: "Ringwood",
        city: "Melbourne",
        state: "VIC",
        zip: "3134"
    },
    website: "http://www.george-li.com",
    resumedownload: "/files/resume.docx",
    social: [
    {
        name: "linkedin",
        url: "https://www.linkedin.com/in/georgejli",
        className: "fa fa-linkedin fa-2x"
    },
    {
        name: "bitbucket",
        url: "https://bitbucket.org/PGSCC",
        className: "fa fa-bitbucket fa-2x"
    }]
}
export const resume = {
    education: [
    {
        school: "Deakin University",
        degree: "Bachelor of Computer Science, Major in Robotics and Cyber-Physical Computing",
        graduated: "October 2019",
        description: "I have been passionate about IT since I was a child.  And Deakin university is a good option to go.  Here I took my Bachelors of Computer Science with a major in Robotics and Cyber-Physical Computing and achieved a much more thorough education in the underlying theory, concepts, and science behind more complex programming."
    }
    ],
    work: [
    {
        company: "Wurth Australia, Melbourne",
        title: "Logistics Software Engineer",
        years: "May 2022 - Current",
        lists: [
            {
                list: "• Solely architected and deployed a pioneering warehouse/operation portal to streamline warehouse management and operations, utilizing .NET 6 & 8, Blazor WebAssembly, and MudBlazor for development."
            },
            {
                list: "• Developed and integrated multiple dynamic dashboards with ApexCharts in Blazor, enabling real-time productivity monitoring and management of warehouse activities, including specialized dashboards for picking operations, inbound goods, and picker performance."
            },
            {
                list: "• Innovated a robotic control interface within the portal, facilitating direct communication with HikRobot through .NET API, revolutionizing pallet movements and operational efficiency."
            },
            {
                list: "• Played a pivotal role in the system's entire lifecycle, from concept to deployment, ensuring the solution not only met but exceeded operational requirements and quality standards."
            },
            {
                list: "• Collaborated extensively with operations and warehouse teams to customize the portal, significantly improving workflow efficiency and providing actionable insights through productivity and credit reports, enhancing decision-making and customer service capabilities."
            }
        ]
    },
    {
        company: "eMatrix Training, Melbourne",
        title: "Application Support Developer",
        years: "August 2021 - November 2021",
        lists: [
            {
                list: "• Spearheaded development support for ASP.Net Core MVC web applications, streamlining deployment processes with the WiX Toolset to enhance application performance and reliability."
            },
            {
                list: "• Led daily maintenance initiatives for web applications, ensuring optimal operation and consistent reliability through proactive troubleshooting and updates."
            },
            {
                list: "• Delivered comprehensive technical support, encompassing both hardware and software, significantly improving system performance and user satisfaction."
            },
            {
                list: "• Played a pivotal role in the seamless integration and support of Microsoft Teams and Microsoft 365 applications, facilitating efficient communication and collaboration across teams."
            },
            {
                list: "• Addressed a wide spectrum of IT-related challenges, effectively maintaining system integrity and supporting a robust IT infrastructure to meet eMatrix's operational needs."
            }
        ]
    },
    {
        company: "FutureLabs, Melbourne",
        title: "Junior developer",
        years: "March 2020 - August 2021",
        lists: [
            {
                list: "• Elevated web development standards by supporting and maintaining sites using React.js, Bootstrap, and Laravel, focusing on best practices and exceptional code quality."
            },
            {
                list: "• Implemented rigorous testing protocols to ensure high-quality product and service delivery, significantly reducing post-launch issues."
            },
            {
                list: "• Enhanced collaborative project management and technical support during events, working effectively with cross-functional teams to meet critical deadlines."
            },
            {
                list: "• Designed user-centric interfaces for cross-platform applications using Xamarin Forms, streamlining content management for efficient data retrieval."
            },
            {
                list: "• Drove innovation in software development, creating chatbots and virtual reality applications with Node.js, and skillfully handled data migration and custom function development."
            }
        ]
    },
    {
        company: "Darkspede, Melbourne",
        title: "R&D Assistant",
        years: "July 2019 - October 2020",
        lists: [
            {
                list: "• Conducted research in Extended Reality (XR) and Virtual Reality (VR) technologies, and software development."
            },
            {
                list: "• Utilized cross-platform on Visual Studio for modern applications."
            },
            {
                list: "• Supported teammates to use Unity and testing processes."
            },
            {
                list: "• Developing an interface of an application with Mocking Bot."
            },
            {
                list: "• Providing regular updates to the team in regard to projects' status."
            },
            {
                list: "• Keeping updated with new technologies, platforms and frameworks."
            }
        ]
    },
    {
        company: "Absolute Solution, Melbourne",
        title: "Service Engineer in Computer Maintenance (casual)",
        years: "October 2016 - October 2017",
        lists: [
            {
                list: "• Fixed software issues and identified hardware issues."
            },
            {
                list: "• Built gaming desktops upon customer’s request."
            }
        ]
    },
    {
        company: "McDonald’s, Melbourne",
        title: "Shift Supervisor/Member staff",
        years: "February 2015 - February 2020",
        lists: [
            {
                list: "• Processed cash responsibilities and deposits."
            },
            {
                list: "• Provided First Aid when necessary."
            },
            {
                list: "• Responded to and effectively resolved customer complaints in a timely manner to ensure satisfaction."
            },
            {
                list: "• Helped team to improve customer satisfaction."
            },
            {
                list: "• Supervised, motivated, evaluated, and coached crews and facilitated effective training programs."
            }
        ]
    }],
    skills: [
        {"name": "Blazor Applications", "level": "100%"},
        {"name": "HTML", "level": "100%"},
        {"name": "CSS", "level": "100%"},
        {"name": "JavaScript", "level": "100%"},
        {"name": "C#", "level": "100%"},
        {"name": "React", "level": "100%"},
        {"name": "SQL & JSON", "level": "100%"},
        {"name": "Docker", "level": "100%"},
        {"name": "Microsoft 365", "level": "100%"},
        {"name": "Object-Oriented Programming (OOP)", "level": "100%"},
        {"name": "MudBlazor", "level": "100%"},
        {"name": "Azure", "level": "100%"},
        {"name": ".NET API", "level": "100%"},
        {"name": "Unity", "level": "100%"},
        {"name": "NodeJS", "level": "100%"},
        {"name": "MSSQL", "level": "100%"},
        {"name": "CI/CD Practices", "level": "100%"},
        {"name": ".NET MVC", "level": "100%"},
        {"name": "Responsive Web Design", "level": "100%"},
        {"name": "Bitbucket Pipelines", "level": "100%"}
]
}
export const portfolio = {
    projects: [
    {
        title: "Alexa Mircosite",
        category: "Technologies: html, css, js, bootstrap",
        image: "alexa_microsite.png",
        url: "https://pgscc.bitbucket.io/amazon_mirocsite/"
    },
    {
        title: "Ampol Laravel form",
        category: "Technologies: html, css, js, php, laravel, mysql, bootstrap",
        image: "ampol_form.png",
        url: "https://geroge-demo-laravel-form.herokuapp.com/1"
    },
    {
        title: "My Resume",
        category: "Technologies: html, css, react, bootstrap",
        image: "resume.png",
        url: "https://www.george-li.com"
    },
    {
        title: "Restaurant Demo",
        category: "Technologies: html, css, react, bootstrap",
        image: "restaurant_demo.png",
        url: "https://george-demo-restaurant.vercel.app"
    },
    {
        title: "Flappybird",
        category: "Technologies: Unity, C#, WebGL",
        image: "flappybird.png",
        url: "https://pgscc.bitbucket.io/flappybird/"
    },
    {
        title: "Gym Progress App",
        category: "Technologies: Blazor Web App, Mini APIs, Mudblazor, Apex-Chart Blazor",
        image: "gymApp.png",
        url: "https://gymprogressapp.george-li.com/"
    }]
}
export const testimonials = {
    testimonials: [
    {
        text: "",
        user: ""
    },
    {
        text: "",
        user: ""
    }]
}
