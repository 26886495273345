import React, { useState } from 'react';
import './navbar.css';
import { Link } from 'react-scroll';

function Navbar() {
    const [navbar, setNavbar] = useState(false);
    const [show, showNavbar] = useState(true);


    function changeBackground() {
        /* Navbar for others */
        if (window.scrollY >= 753) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }

        /* Navbar for home */
        if (window.scrollY > 20) {
            if( window.scrollY < 753 ) {
                showNavbar(false);
            } else {
                showNavbar(true);
            }
        } else {
            showNavbar(true);
        }
    }


    window.addEventListener('scroll', changeBackground);

    function handleCollapse() {
        var nav = document.getElementById("navbarNav");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
    }
    
    return(
        show && <nav className={navbar ? "navbar navbar-expand-lg navbar-dark fixed-top notHome" : "navbar navbar-expand-lg navbar-dark fixed-top "}>
            <button className="navbar-toggler" id="navbarBtn" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="home" spy={true} smooth={true} duration={1000} onClick={handleCollapse}>HOME</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="about" spy={true} smooth={true} duration={1000} onClick={handleCollapse}>ABOUT</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="resume" spy={true} smooth={true} duration={1000} onClick={handleCollapse}>RESUME</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="portfolio" spy={true} smooth={true} duration={1000} onClick={handleCollapse}>PORTFOLIO</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="testimonials" spy={true} smooth={true} duration={500} onClick={handleCollapse}>TESTIMONIALS</Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="contact" spy={true} smooth={true} duration={500} onClick={handleCollapse}>CONTACT</Link>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;