import React from 'react';
import { Element } from 'react-scroll';
import './portfolio.css';

function Portfolio(props) {

    var projects = props.data.projects.map(function(projects){
        var projectImage = require('../../images/portfolio/'+projects.image).default;
        return <div key={projects.title} className="columns portfolio-item col-lg-3 pb-5">
           <div className="item-wrap">
                <a href={projects.url} title={projects.title} target="_blank" rel="noreferrer">
                    <img alt={projects.title} src={projectImage} className="col-12" />
                    <div className="overlay">
                        <div className="portfolio-item-meta">
                        <h5>{projects.title}</h5>
                            <p>{projects.category}</p>
                        </div>
                        </div>
                    <div className="link-icon"><i className="fa fa-link"></i></div>
                </a>
            </div>
        </div>
    })

    return(
        <Element name="portfolio" className="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Check Out Some of My Works.</h1>
                        <div id="portfolio-wrapper" className="pt-5">
                            <div className="row">
                                {projects}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

        </Element>
    );
}

export default Portfolio;