import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';

function Footer(props) {

    var social = props.data.social.map(function(network){
        return (
            <div className="col-2 col-lg-1" key={network.name}>
                <li><a href={network.url} target="_blank" rel="noreferrer"><i className={network.className}></i></a></li>
            </div>
        );
    })

    return(
        <footer className="container-fluid pb-3 pt-5">
            <div className="col-md-12">
                <div className="row social justify-content-center">
                    {social}
                </div>

                <div className="copyright p-5">
                    <li>&copy; Copyright 2024 George Li</li>
                </div>
            </div>
            <div className="go-top">
                <Link to="home" spy={true} smooth={true} duration={3000}><i className="fa fa-arrow-circle-up fa-3x" aria-hidden="true"></i></Link>
            </div>    
        </footer>
    );
}

export default Footer;